import { AnalyticsBrowser } from '@segment/analytics-next';
import './src/styles/global.css';

export const onClientEntry = async () => {
  const analytics = await AnalyticsBrowser.load({
    writeKey: process.env.GATSBY_PUBLIC_SEGMENT_WRITE_KEY,
  });
  window.analytics = analytics;
};

export const onRouteUpdate = ({ location }) => {
  if (window?.analytics?.[0]?.page) {
    window?.analytics?.[0]?.page({
      path: location.pathname,
      url: window.location.href,
    });
  }
};
